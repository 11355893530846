<template>
  <div>
    <div class="flex md12">
      <va-card :title="'release list'">
        <div class="row align--center">
          <div class="flex xs12 md6">
            <va-input
              :value="term"
              :placeholder="'Search emails'"
              @input="search"
              removable
            >
              <va-icon
                name="fa fa-search"
                slot="prepend"
              />
            </va-input>
          </div>
          <div class="flex xs12 md3 offset--md3">
            <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              no-clear
            />
          </div>
        </div>

        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          :loading="loading"
          clickable
          hoverable
        />
      </va-card>
    </div>
  </div>
</template>

<script>
import {debounce} from 'lodash';
export default {
  data() {
    return {
      email: '',
      loading: true,
      emailErrors: [],
      users: [],
      term: null,
      perPage: '10',
      perPageOptions: ['10', '50', '100', '250'],
    };
  },

  async created() {
    this.$db.collection('notificationList').orderBy('email').get().then((snapshot)=>{
      snapshot.docs.forEach((user) => {
        const userData = user.data();
        userData.uid = user.id;
        this.users.push(userData);
      });

      // only shows the unique users
      this.users = this.users.filter((a, i)=> this.users.findIndex((user)=>user.email === a.email) === i);
    });
    this.loading = false;
  },
  computed: {
    fields() {
      return [{
        name: 'email',
        title: this.$t('tables.headings.email'),
        sortField: 'email',
        width: '30%',
      }];
    },
    filteredData() {
      if (!this.term || this.term.length < 1) {
        return this.users;
      }

      return this.users.filter((item) => {
        let returnUser = false;
        if (item.email) {
          if (item.email.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnUser = true;
          }
        }

        return returnUser;
      });
    },
  },
  methods: {
    search: debounce(function(term) {
      /* eslint-disable */
      this.term = term;
    }, 100)
  }
};
</script>

<style>

</style>
